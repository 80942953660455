body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  color: #000;
  text-align: center;
  background: #fff;
  justify-content: center;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  display: flex;
}

nav a {
  padding: 20px;
}

/*# sourceMappingURL=index.2a76eccf.css.map */
